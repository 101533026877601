import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Page,
  Column,
  Row,
  Loader,
  Spinner,
} from '@oliasoft-open-source/react-ui-library';
import { riskUnselected, addInitialRisk } from '~store/entities/risks/risks';
import { selectIsPageDisabled } from '~src/store/entities/projects/selectors';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { projectPages } from '~src/enums/projects';
import { RisksDrawer } from './drawer/risks.drawer';
import { RiskForm } from './risk.form';
import { RisksList } from './risks.list';
import { NoRisks } from './no-risks';
import { RiskDeleteModal } from './risk-delete.modal';

const Risks = ({
  risks,
  isFetching,
  isAdding,
  activeRisk,
  riskUnselected,
  addInitialRisk,
  projectId,
  isPageDisabled,
}) => {
  const [showForm, setShowForm] = useState(null);
  const [riskModalVisible, setRiskModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const onClickAdd = () => {
    riskUnselected();
    addInitialRisk(projectId);
    setShowForm(true);
  };

  let form = null;
  if (risks.length === 0 && !isFetching) {
    form = <NoRisks onClickAdd={onClickAdd} isPageDisabled={isPageDisabled} />;
  }
  if (showForm) {
    form = (
      <RiskForm
        risk={activeRisk}
        isAdding={isAdding}
        projectId={projectId}
        isPageDisabled={isPageDisabled}
      />
    );
  }

  return (
    <>
      <Page padding={false}>
        {isFetching && (
          <Loader text="Fetching..." theme="light" cover>
            <Spinner dark />
          </Loader>
        )}
        <Row spacing={0} height="100%">
          <RisksDrawer />
          <Column scroll width="250px" borderRight>
            <RisksList
              setShowForm={setShowForm}
              isAdding={isAdding}
              setSelectedId={setSelectedId}
              setRiskModalVisible={setRiskModalVisible}
              onClickAdd={onClickAdd}
              projectId={projectId}
              isPageDisabled={isPageDisabled}
            />
          </Column>
          <Column scroll padding>
            {form}
          </Column>
        </Row>
      </Page>
      {riskModalVisible && (
        <RiskDeleteModal
          setModalVisible={setRiskModalVisible}
          riskId={selectedId}
          projectId={projectId}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    risks: { list, isFetching, isAdding },
  } = entities;
  const activeRisk = list.find((item) => item.active);

  return {
    risks: list,
    isFetching,
    isAdding,
    activeRisk,
    isPageDisabled: selectIsPageDisabled(entities, projectPages.PROJECT_RISKS),
  };
};

const mapDispatchToProps = {
  riskUnselected,
  addInitialRisk,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(Risks),
  { isPage: true },
);

export { Container as Risks };
