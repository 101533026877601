import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';

// const BASE_URL = '/api/v1/integration/external';
// const URL = '/zendesk';
export const fetchDomains = createAsyncThunk('api/fetchDomains', async () => {
  //this won't work so i put a dummy array as return value for this function call

  const domains = ['oliasoft.com'];

  return domains;
});

export const zendeskSlice = createSlice({
  name: 'zendesk',
  initialState: {
    domains: [],
    isLoaded: false, // widget loaded
    isChatOpen: false,
    isSubmitting: false,
    result: null,
    error: null,
  },
  reducers: {
    toggleChatOpen: (zendesk, action) => {
      zendesk.isChatOpen = action?.payload;
    },
    userFeedbackSubmittedSuccess: (state, { payload }) => {
      state.result = payload;
      state.isSubmitting = false;
      state.error = null;
    },
    userFeedbackRequested: (state) => {
      state.isSubmitting = true;
      state.error = null;
      state.result = null;
    },
    userFeedbackRequesteFailed: (state, { payload }) => {
      state.isSubmitting = false;
      state.error = payload;
      state.result = null;
    },
  },
  extraReducers: {
    [fetchDomains.fulfilled]: (zendesk, action) => {
      const hasToken = action.payload?.length;
      if (hasToken) {
        zendesk.isLoaded = true;
        zendesk.domains = action.payload;
      } else {
        zendesk.isLoaded = false;
      }
    },
    [fetchDomains.rejected]: (zendesk) => {
      zendesk.isLoaded = true;
    },
  },
});

export const {
  toggleChatOpen,
  userFeedbackRequesteFailed,
  userFeedbackRequested,
  userFeedbackSubmittedSuccess,
} = zendeskSlice.actions;

export default zendeskSlice.reducer;

/**
    @param {object} body
    @param {string} body.feedback user provided feedback
    @param {string} body.subject subject we use for ticketing system in zendesk
 */
export const submitUserFeedback = (body) => {
  const url = '/api/v1/integration/external/zendesk';
  return apiCallBegan({
    url,
    body: JSON.stringify(body),
    method: 'POST',
    onSuccess: (res) => userFeedbackSubmittedSuccess(res),
    onStart: userFeedbackRequested.type,
    onError: userFeedbackRequesteFailed.type,
  });
};
