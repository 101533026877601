// import { NoteModule } from '~src/enums/notes-enums';

const baseGuideUrl = 'https://documentation.oliasoft.com';

const links = {
  projects: [
    {
      translationKey: 'gettingStarted',
      link: `${baseGuideUrl}`,
    },
    // ],
    // [NoteModule.WellDetails]: [
    //   {
    //     translationKey: 'wellDetails_wellDetails',
    //     link: `${baseGuideUrl}`,
    //   },
    // ],
    // [NoteModule.ActivityModel]: [
    //   {
    //     translationKey: 'activityModel_title',
    //     link: `${baseGuideUrl}`,
    //   },
    // ],
    // [NoteModule.Risks]: [
    //   {
    //     translationKey: 'projectModifiers_title',
    //     link: `${baseGuideUrl}`,
    //   },
    // ],
    // [NoteModule.CostSetup]: [
    //   {
    //     translationKey: 'costSetup_costSetup',
    //     link: `${baseGuideUrl}`,
    //   },
    // ],
    // [NoteModule.ResultOverview]: [
    //   {
    //     translationKey: 'simulations_resultOverview',
    //     link: `${baseGuideUrl}`,
    //   },
    // ],
    // [NoteModule.TimeTracker]: [
    //   {
    //     translationKey: 'timeTracker_timeTracker',
    //     link: `${baseGuideUrl}`,
    //   },
    // ],
  ],
};

export { links };

export default links;
