import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Column,
  Row,
  Heading,
  Page,
  Divider,
  Loader,
  Spinner,
} from '@oliasoft-open-source/react-ui-library';
import { setCurrentPage } from '~src/store/entities/navigation/navigation';
import { getProjects } from '~store/entities/projects/projects';
import { DeleteModal } from '~views/projects/delete.modal';
import { CountriesList } from '~views/projects/countries/countries.list';
import { CountryModal } from '~views/projects/countries/country.modal';
import { FieldsList } from '~views/projects/fields/fields.list';
import { FieldModal } from '~views/projects/fields/field.modal';
import { SitesList } from '~views/projects/sites/sites.list';
import { SiteModal } from '~views/projects/sites/site.modal';
import { WellsList } from '~views/projects/wells/wells.list';
import { WellModal } from '~views/projects/wells/well.modal';
import { WellboresList } from '~views/projects/wellbores/wellbores.list';
import { WellboreModal } from '~views/projects/wellbores/wellbore.modal';
import { DesignsList } from '~views/projects/designs/designs.list';
import { DesignModal } from '~views/projects/designs/design.modal';
import { EstimateList } from '~views/projects/estimates/estimates.list';
import { EstimateModal } from '~views/projects/estimates/estimate.modal';
import { CreateModal } from '~views/projects/create.modal';
import translations from '~src/internationalisation/translation-map.json';
import { updateProjectCategory } from '~src/store/entities/project-settings/project-settings';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const Projects = ({
  setCurrentPage,
  getProjects,
  activeCountry,
  activeField,
  activeSite,
  activeWell,
  activeWellbore,
  activeDesign,
  countriesList,
  fieldsList,
  sitesList,
  wellsList,
  wellboresList,
  designsList,
  estimatesList,
  countriesOptions,
  isFetching,
  estimates,
  columnWidth = '14.3%',
  updateProjectCategory,
}) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const [selectedId, setSelectedId] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedList, setSelectedList] = useState(null);
  const [countryModalVisible, setCountryModalVisible] = useState(false);
  const [fieldModalVisible, setFieldModalVisible] = useState(false);
  const [siteModalVisible, setSiteModalVisible] = useState(false);
  const [wellModalVisible, setWellModalVisible] = useState(false);
  const [wellboreModalVisible, setWellboreModalVisible] = useState(false);
  const [designModalVisible, setDesignModalVisible] = useState(false);
  const [estimateModalVisible, setEstimateModalVisible] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [isImport, setIsImport] = useState(false);

  useEffect(() => {
    const activeProject = JSON.parse(localStorage.getItem('activeProject'));
    getProjects(company, activeProject?.projectId);
  }, []);

  useEffect(() => {
    setCurrentPage('projects');
  }, [setCurrentPage]);

  const onClickDelete = (id, list) => {
    setSelectedId(id);
    setSelectedList(list);
    setDeleteModalVisible(true);
  };

  const onUpdateProjectCategory = async (id, category) => {
    await updateProjectCategory(id, category);
    await getProjects(company, id);
  };

  return (
    <>
      <Page left={0} padding={false}>
        {isFetching && (
          <Loader text={t(translations.fetching)} theme="light" cover>
            <Spinner dark />
          </Loader>
        )}
        <Row justifyContent="space-between" alignItems="center">
          <Column flex={false} padding="var(--padding)">
            <Heading top marginBottom={0} testId="estimate-label">
              {t(translations.estimates)}
            </Heading>
          </Column>
          <Column flex={false} padding="var(--padding)">
            <Button
              name="create-estimate"
              label={t(translations.projects_createEstimate)}
              onClick={() => {
                setIsImport(false);
                setCreateModalVisible(true);
              }}
              colored
            />
          </Column>
        </Row>
        <Divider margin={0} />
        <Row spacing={0} height="100%">
          <Column scroll width={columnWidth} borderRight>
            <CountriesList
              onClickDelete={onClickDelete}
              setCountryModalVisible={setCountryModalVisible}
              countries={countriesList}
            />
          </Column>
          <Column scroll width={columnWidth} borderRight>
            <FieldsList
              onClickDelete={onClickDelete}
              setFieldModalVisible={setFieldModalVisible}
              setEditItem={setEditItem}
              fields={fieldsList}
              addButtonDisabled={countriesList.length === 0 || !activeCountry}
            />
          </Column>
          <Column scroll width={columnWidth} borderRight>
            <SitesList
              onClickDelete={onClickDelete}
              setSiteModalVisible={setSiteModalVisible}
              setEditItem={setEditItem}
              sites={sitesList}
              addButtonDisabled={fieldsList.length === 0 || !activeField}
            />
          </Column>
          <Column scroll width={columnWidth} borderRight>
            <WellsList
              onClickDelete={onClickDelete}
              setWellModalVisible={setWellModalVisible}
              setEditItem={setEditItem}
              wells={wellsList}
              addButtonDisabled={sitesList.length === 0 || !activeSite}
            />
          </Column>
          <Column scroll width={columnWidth} borderRight>
            <WellboresList
              onClickDelete={onClickDelete}
              setWellboreModalVisible={setWellboreModalVisible}
              setEditItem={setEditItem}
              wellbores={wellboresList}
              addButtonDisabled={wellsList.length === 0 || !activeWell}
            />
          </Column>
          <Column scroll width={columnWidth} borderRight>
            <DesignsList
              onClickDelete={onClickDelete}
              setDesignModalVisible={setDesignModalVisible}
              setEditItem={setEditItem}
              designs={designsList}
              addButtonDisabled={wellboresList.length === 0 || !activeWellbore}
            />
          </Column>
          <Column scroll width={columnWidth}>
            <EstimateList
              onClickDelete={onClickDelete}
              setEstimateModalVisible={setEstimateModalVisible}
              setEditItem={setEditItem}
              estimates={estimatesList}
              addButtonDisabled={designsList.length === 0 || !activeDesign}
              onUpdateProjectCategory={onUpdateProjectCategory}
            />
          </Column>
        </Row>
      </Page>
      {countryModalVisible && (
        <CountryModal
          setModalVisible={setCountryModalVisible}
          countriesOptions={countriesOptions}
        />
      )}
      {fieldModalVisible && (
        <FieldModal
          setModalVisible={setFieldModalVisible}
          activeCountry={activeCountry}
          editItem={editItem}
          setEditItem={setEditItem}
        />
      )}
      {siteModalVisible && (
        <SiteModal
          setModalVisible={setSiteModalVisible}
          activeField={activeField}
          editItem={editItem}
          setEditItem={setEditItem}
        />
      )}
      {wellModalVisible && (
        <WellModal
          setModalVisible={setWellModalVisible}
          activeSite={activeSite}
          editItem={editItem}
          setEditItem={setEditItem}
        />
      )}
      {wellboreModalVisible && (
        <WellboreModal
          setModalVisible={setWellboreModalVisible}
          activeWell={activeWell}
          editItem={editItem}
          setEditItem={setEditItem}
        />
      )}
      {designModalVisible && (
        <DesignModal
          setModalVisible={setDesignModalVisible}
          activeWellbore={activeWellbore}
          editItem={editItem}
          setEditItem={setEditItem}
        />
      )}
      {estimateModalVisible && (
        <EstimateModal
          setModalVisible={setEstimateModalVisible}
          activeDesign={activeDesign}
          editItem={editItem}
          setEditItem={setEditItem}
        />
      )}
      {deleteModalVisible && (
        <DeleteModal
          setModalVisible={setDeleteModalVisible}
          selectedId={selectedId}
          selectedList={selectedList}
        />
      )}
      {createModalVisible && (
        <CreateModal
          setModalVisible={setCreateModalVisible}
          countriesOptions={countriesOptions}
          countriesList={countriesList}
          isImport={isImport}
          estimates={estimates}
          activeCountry={activeCountry}
          activeField={activeField}
          activeSite={activeSite}
          activeWell={activeWell}
          activeWellbore={activeWellbore}
          activeDesign={activeDesign}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const { countries, fields, sites, wells, wellbores, designs, estimates } =
    entities.projects.lists;
  const activeCountry = countries.find((country) => country.active) || null;
  const activeField = fields.find((field) => field.active) || null;
  const activeSite = sites.find((site) => site.active) || null;
  const activeWell = wells.find((well) => well.active) || null;
  const activeWellbore = wellbores.find((wellbore) => wellbore.active) || null;
  const activeDesign = designs.find((design) => design.active) || null;
  const existingCountries = countries.map((country) => country.name);
  const countriesOptions = entities.projects.countriesList
    .filter((country) => !existingCountries.includes(country.name))
    .map((country) => ({
      label: country.name,
      value: country.countryId,
    }));

  const fieldsList = activeCountry
    ? fields.filter(
        (item) => item.projectCountryId === activeCountry.projectCountryId,
      )
    : [];
  const sitesList = activeField
    ? sites.filter((item) => item.projectFieldId === activeField.projectFieldId)
    : [];
  const wellsList = activeSite
    ? wells.filter((item) => item.projectSiteId === activeSite.projectSiteId)
    : [];
  const wellboresList = activeWell
    ? wellbores.filter(
        (item) => item.projectWellId === activeWell.projectWellId,
      )
    : [];
  const designsList = activeWellbore
    ? designs.filter(
        (item) => item.projectWellboreId === activeWellbore.projectWellboreId,
      )
    : [];
  const estimatesList = activeDesign
    ? estimates.filter(
        (item) => item.projectDesignId === activeDesign.projectDesignId,
      )
    : [];

  return {
    activeCountry,
    activeField,
    activeSite,
    activeWell,
    activeWellbore,
    activeDesign,
    countriesList: countries,
    fieldsList,
    sitesList,
    wellsList,
    wellboresList,
    designsList,
    estimatesList,
    countriesOptions,
    isFetching: entities.projects.isFetching,
    estimates,
    showCompareModal: entities.compareEstimates.showCompareModal,
  };
};
const mapDispatchToProps = {
  setCurrentPage,
  getProjects,
  updateProjectCategory,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(Projects),
  { style: { position: 'absolute', top: '50px' } },
);

export { Container as Projects };
