/* global zE */
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  fetchDomains,
  toggleChatOpen,
} from '~src/store/entities/zendesk/zendesk';

const portal = ({
  email,
  name,
  domains,
  isLoaded,
  fetchDomains,
  toggleChatOpen,
  isChatOpen,
  ...props
}) => {
  const [webWidget, setWebWidget] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const { i18n } = useTranslation();

  const renderWebWidget = (domains) => {
    let createdPortal = null;
    const chatWidget = document.createElement('script');
    chatWidget.src =
      'https://static.zdassets.com/ekr/snippet.js?key=c1466962-6155-4e54-a4d0-d8554b013900';
    chatWidget.type = 'text/javascript';
    chatWidget.id = 'ze-snippet';
    chatWidget.async = true;

    const head = document.getElementsByTagName('head')[0];
    const userEmailDomain = email?.split('@')[1];
    const shouldChatSuppress = !domains.includes(userEmailDomain);

    chatWidget.onload = () => {
      zE(() => {
        if (!isChatOpen) {
          zE.hide();
        }
        if (!isMounted) {
          zE.identify({
            name,
            email,
          });
        }

        zE.setLocale(i18n.language);
        zE('webWidget:on', 'close', () => {
          toggleChatOpen(false);
          zE.hide();
        });
      });

      window.zESettings = {
        webWidget: {
          chat: {
            suppress: shouldChatSuppress,
          },
          contactForm: {
            suppress: false,
          },
          helpCenter: {
            suppress: true,
          },
          talk: {
            suppress: true,
          },
          answerBot: {
            suppress: true,
          },
        },
      };
    };

    createdPortal = createPortal(props.children, head.appendChild(chatWidget));

    setIsMounted(true);
    setWebWidget(createdPortal);
  };

  useEffect(() => {
    fetchDomains();
    return () => {
      setWebWidget(null);
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      renderWebWidget(domains);
    }
  }, [isLoaded, i18n.language]);

  return <div>{webWidget}</div>;
};

const mapStateToProps = ({ entities }) => ({
  domains: entities.zendesk.domains,
  isLoaded: entities.zendesk.isLoaded,
  isChatOpen: entities.zendesk.isChatOpen,
});

const mapDispatchToProps = {
  fetchDomains,
  toggleChatOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(portal);
